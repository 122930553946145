import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import media from 'styled-media-query'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { Link } from '../i18n'

const Wrapper = styled.div`
  flex: 1 0 calc(50% - 1.45rem);
  margin: calc(1.45rem / 2);
  min-width: 300px;
  max-width: 350px;
  background: rgba(42, 42, 42, 0.7);
  box-shadow: 3px 3px 2px rgb(23, 23, 23);
  transition: box-shadow 0.2s, transform 0.2s;
  text-align: center;

  &:hover {
    box-shadow: 1px 1px 2px rgb(23, 23, 23);
    transform: translateY(2px);
    cursor: pointer;
  }

  &:active {
    box-shadow: none;
    transform: translateY(3px);
  }

  ${media.lessThan('small')`
    min-width: 250px;
  `}
`

const ResetLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const ModelNameContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  margin-top: 10px;
  margin-bottom: 15px;

  &::after {
    content: '';
    height: 1.5px;
    width: 90%;
    position: absolute;
    bottom: 0;
    background: #757575;
  }
`

const ModelName = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0;
  line-height: 1.8rem;
`

const Model = styled.h4`
  font-size: 1rem;
  line-height: 1.2rem;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 15px;
`

const Price = styled.p`
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-bottom: 15px;
`

const InfoText = styled.p`
  color: #a5a5a5;
  font-size: 0.75rem;
  margin-bottom: 2px;
`

const MetaContainer = styled.div``

const OfferTile = ({
  currency,
  node: {
    uid,
    data: { thumbnail, price, name, model },
  },
}) => (
  <Wrapper>
    <ResetLink to={`/offers/${uid}`}>
      <Img
        alt={thumbnail.alt}
        fluid={thumbnail.localFile.childImageSharp.fluid}
      />
      <MetaContainer>
        <ModelNameContainer>
          <ModelName>{name}</ModelName>
        </ModelNameContainer>
        <InfoText>
          <FormattedMessage id="model" />:
        </InfoText>
        <Model>{model}</Model>
        {/* <InfoText>
          <FormattedMessage id="price" />:
        </InfoText>
        <Price>
          <FormattedNumber
            value={price}
            style="currency" // eslint-disable-line react/style-prop-object
            currency={currency}
          />
        </Price> */}
      </MetaContainer>
    </ResetLink>
  </Wrapper>
)

export default OfferTile
