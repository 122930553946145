import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { injectIntl } from 'react-intl'

import SaleFilters from '../components/SaleFilters'
import OfferTile from '../components/OfferTile'
import SEO from '../components/seo'

const Container = styled.section`
  max-width: 1000px;
  width: 100%;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blackOverlay};
  margin: 30px 0;
  padding: 40px;

  ${media.lessThan('small')`
    padding: 30px;
    margin: 0;
  `}
`

const Heading = styled.div`
  border-bottom: 1px solid #80808061;

  h1 {
    text-align: center;
  }
`

const Wrapper = styled.div`
  display: flex;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`

const Filters = styled(SaleFilters)`
  flex: 0 0 200px;
  padding-right: 1.45rem;
  padding-top: 1.45rem;
  border-right: 1px solid #80808061;

  ${media.lessThan('large')`
    border-right: none;
    padding-right: 0;

    border-bottom: 1px solid #80808061;
    padding-bottom: 1.45rem;
  `}
`

const OfferTiles = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex: auto;
  margin: calc(-1.45rem / 2);
  padding-top: 1.45rem;
  padding-left: 1.45rem;

  ${media.lessThan('large')`
    padding-left: 0;
  `}
`

class SalePage extends PureComponent {
  allOffers = this.props.data.allPrismicOffer

  state = {
    offers: this.allOffers && this.allOffers.edges,
  }

  handleFilter = ({ filteredPartners, fromPrice, toPrice }) => {
    const filteredOffers =
      this.allOffers &&
      this.allOffers.edges
        .filter(({ node: { data: { partner } } }) =>
          filteredPartners.includes(partner)
        )
        .filter(
          ({
            node: {
              data: { price },
            },
          }) => price >= fromPrice && price <= toPrice
        )
    this.setState({ offers: filteredOffers })
  }

  render() {
    const {
      intl,
      data: {
        allPrismicSale: {
          edges: [
            {
              node: {
                data: { heading, offer_currency },
              },
            },
          ],
        },
      },
    } = this.props
    const { offers } = this.state

    const uniquePartners =
      this.allOffers &&
      this.allOffers.edges
        .map(({ node }) => node.data.partner)
        .filter((value, index, self) => self.indexOf(value) === index)

    return (
      <>
        <SEO title={intl.formatMessage({ id: 'sale' })} />
        <Container>
          <Heading dangerouslySetInnerHTML={{ __html: heading.html }} />
          {offers && (
            <Wrapper>
              <Filters
                availablePartners={uniquePartners}
                onFilter={this.handleFilter}
              />
              <OfferTiles>
                {offers.map(({ node }) => (
                  <OfferTile
                    key={node.uid}
                    node={node}
                    currency={offer_currency}
                  />
                ))}
              </OfferTiles>
            </Wrapper>
          )}
        </Container>
      </>
    )
  }
}

export default injectIntl(SalePage)

export const query = graphql`
  query($locale: String!) {
    allPrismicOffer(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            name
            model
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 345, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            partner
            price
          }
        }
      }
    }
    allPrismicSale(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            heading {
              html
            }
            offer_currency
          }
        }
      }
    }
  }
`
