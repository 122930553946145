import React, { PureComponent } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { injectIntl, FormattedMessage } from 'react-intl'

import Button from '../components/Button'

const FiltersCaption = styled.h4`
  ${media.lessThan('large')`
    text-align: center;
  `}
`

const Filters = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan('large')`
    flex-direction: row;
    justify-content: space-evenly;
  `}
`

const GroupCaption = styled.h6`
  margin-bottom: 15px;
`

const FilterGroup = styled.div`
  margin-bottom: 20px;
`

const PartnerFilter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  & > label {
    padding-left: 11px;
    user-select: none;
  }
`

const Input = styled.div`
  position: relative;
  margin-bottom: 5px;

  /* Credits to https://codepen.io/Mestika/pen/EKRoeB */
  & > .effect-1 {
    background: none;
    outline: none;
    border: none;
    color: white;
    width: 100%;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
  }

  & > .effect-1 ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${props => props.theme.colors.orange};
    transition: 0.4s;
  }
  & > .effect-1:focus ~ .focus-border {
    width: 100%;
    transition: 0.4s;
  }
`

const FilterButton = styled(Button)`
  width: 114px;
  height: 35px;
  font-size: 0.8125rem;
  display: block;
  margin: 0 auto;
`

class SaleFilters extends PureComponent {
  state = {
    partners: this.props.availablePartners.reduce(
      (obj, cur) => ({ ...obj, [cur]: false }),
      {}
    ),
    prices: {
      fromPrice: 0,
      toPrice: Infinity,
    },
  }

  handlePartnerChange = event => {
    const { name, checked } = event.target
    const partners = {
      ...this.state.partners,
      [name]: checked,
    }
    this.setState({ partners })
  }

  handlePriceChange = event => {
    const { name, value } = event.target
    const coercedValue =
      value === '' ? (name === 'fromPrice' ? 0 : Infinity) : +value

    const prices = {
      ...this.state.prices,
      [name]: coercedValue,
    }
    this.setState({ prices })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { partners, prices } = this.state
    const filteredPartners = Object.entries(partners).reduce(
      (list, [key, value]) => {
        value && list.push(key)
        return list
      },
      []
    )

    this.props.onFilter({
      // TODO: can it be refactored somehow?
      filteredPartners: filteredPartners.length
        ? filteredPartners
        : Object.keys(partners),
      ...prices,
    })
  }

  render() {
    const { className, availablePartners, intl } = this.props

    return (
      <form className={className} onSubmit={this.handleSubmit}>
        <FiltersCaption>
          <FormattedMessage id="filters" />:
        </FiltersCaption>
        <Filters>
          <FilterGroup>
            <GroupCaption>
              <FormattedMessage id="manufacturers" />:
            </GroupCaption>
            {availablePartners.map((partner, index) => (
              <PartnerFilter key={partner}>
                <input
                  // can't use partner as it can contain spaces
                  id={`partnerCheckbox${index}`}
                  name={partner}
                  type="checkbox"
                  onChange={this.handlePartnerChange}
                />
                <label htmlFor={`partnerCheckbox${index}`}>{partner}</label>
              </PartnerFilter>
            ))}
          </FilterGroup>
          {/* <FilterGroup>
            <GroupCaption>
              <FormattedMessage id="price" />:
            </GroupCaption>
            <Input>
              <input
                name="fromPrice"
                className="effect-1"
                type="number"
                placeholder={intl.formatMessage({ id: 'fromPricePlaceholder' })}
                onChange={this.handlePriceChange}
              />
              <span className="focus-border" />
            </Input>
            <Input>
              <input
                name="toPrice"
                className="effect-1"
                type="number"
                placeholder={intl.formatMessage({ id: 'toPricePlaceholder' })}
                onChange={this.handlePriceChange}
              />
              <span className="focus-border" />
            </Input>
          </FilterGroup> */}
        </Filters>
        <FilterButton>
          <FormattedMessage id="filterButtonCaption" />
        </FilterButton>
      </form>
    )
  }
}

export default injectIntl(SaleFilters)
